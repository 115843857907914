import { Component, OnInit, OnDestroy, inject, ViewChild, NgZone, ViewContainerRef } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, take, takeUntil } from "rxjs/operators";
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { HttpErrorResponse } from "@angular/common/http";
import {
  ProcessType,
  SPlusDialogCloseResult,
  SPlusDialogSettings,
  SPlusMoreOption,
  SPlusNotificationService,
  SPlusNotificationSettings,
  SPlusOption,
  SplusTabsFormComponent,
} from "@saludplus/forms";
import { ILiquidacionNominaContratoEmpleadoDTO, ILiquidacionNominaDTO, ILiquidacionNominaEmpleadoConceptoDTO } from "@src/app/models/Nomina/Liquidacion.model";
import { LiquidacionService } from "../services/liquidacion.service";
import { LiquidacionSettings } from "../config/liquidacion.settings";
import { KENDO_DIALOGS } from "@progress/kendo-angular-dialog";
import { CommonModule } from "@angular/common";
import { SearchModule } from "@src/app/core/Search/search.module";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ComponentsCoreFormsModule, DirectivesCoreFormsModule } from "@src/app/components/Core/core.saludplus.module";
import { KENDO_BUTTON } from "@progress/kendo-angular-buttons";
import { KENDO_INPUTS } from "@progress/kendo-angular-inputs";
import { EmpleadosSearch } from "../../empleados/config/empleados.search";
import { Empleados } from "@src/app/models/Nomina/Empleados.model";
import { EmpleadosSettings } from "../../empleados/config/empleados.settings";
import { FiltersData, MultipleSelectConfigType } from "@src/app/models/base/filterModel";
import { GridComponent, KENDO_GRID } from "@progress/kendo-angular-grid";
import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component";

import { KENDO_DATEINPUTS } from "@progress/kendo-angular-dateinputs";
import { KENDO_MULTISELECTTREE } from "@progress/kendo-angular-dropdowns";


const kendomodules = [
  KENDO_BUTTON,
  KENDO_INPUTS,
  KENDO_DIALOGS,
  KENDO_GRID,
  KENDO_DATEINPUTS,
  KENDO_MULTISELECTTREE
];

@Component({
    selector: "app-liquidacion",
    templateUrl: "./liquidacion.component.html",
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ComponentsCoreFormsModule,
        DirectivesCoreFormsModule,
        SearchModule,
        ...kendomodules,
    ],
    providers: [EmpleadosSettings]
})
export class LiquidacionComponent extends SplusBaseForm implements OnInit, OnDestroy {
  @ViewChild("container", { read: ViewContainerRef }) public containerRef: ViewContainerRef;
  @ViewChild("gridLiquidarEmpleados") public gridRef: GridComponent;
  @ViewChild('tabsComponent', { static: false }) tabsComponent: SplusTabsFormComponent;
  closePop: boolean = false;
  selectAllFlag: boolean = false;
  indexSelectionRow: number[] = [];
  indexSelectionRowAplicados: number[] = [];
  indexSelectionRowDisponibles: number[] = [];
  showbtnActions: boolean = false;
  // Forms
  liquidacion: ILiquidacionNominaDTO;
  DatosLiquidacion: ILiquidacionNominaDTO;
  selectedItems: number[] = [];
  datosValidos: boolean = false;
  openedDiagLiquidarMasivoEmpleado = false;

  searchMainControl: FormControl = new FormControl();
  searchEmpleadosMainControl: FormControl = new FormControl();

  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Listado",
      icon: "fa-solid fa-magnifying-glass",
      text: "Listado",
      link: "/nomina/liquidacion/listado",
    },
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];
  loadingLiquidar = false;
  //listado de más opciones --
  moreOptions: SPlusMoreOption[] = [
    {
      id: "CrearLiquidacion",
      showIn: ProcessType.view,
      text: "Crear nuevo liquidacion",
      icon: "fa-duotone fa-plus",
      action: () => this.newRecord(),
    },
    {
      id: "EliminarLiquidacion",
      showIn: ProcessType.edit,
      text: "Eliminar",
      icon: "fa-solid fa-trash-can",
      action: () => this.dialogEliminar(),
    },
    {
      id: "CancelarLiquidacion",
      showIn: ProcessType.edit,
      text: "Cancelar Liquidación",
      icon: "fa-duotone fa-solid fa-ban",
      action: () => this.dialogCancelarLiquidacion(),
    },
  ];

  private unsubcribe$ = new Subject<void>();

  filtersEmpleado: FiltersData[] = [];
  openedDiagAgregarEmpleado = false;
  openedDiagLiquidarEmpleado = false;
  dataGridEmpleados: any[] = [];
  dataGridEmpleadosOriginal: any[] = [];

  ListadoEmpleado = EmpleadosSearch

  //child
  @ViewChild('searchListEmployees') searchListEmployees: SearchInListComponent;
  @ViewChild('gridEmpleados') public gridEmpleadosRef: GridComponent;

  //injects
  private service = inject(LiquidacionService);
  private settings = inject(LiquidacionSettings);
  private notificationService = inject(SPlusNotificationService);
  private ngZone = inject(NgZone);
  idLiquidacionEmpleado: number;
  showDeleteButton: boolean = false;
  showLiquidacionMasivaButton: boolean = false;
  empleadosEliminar: any[] = [];
  totalDevengado: number;
  totalDeducciones: number;
  total: number;
  dataSelectedAplicados: ILiquidacionNominaEmpleadoConceptoDTO[] = [];
  dataSelectedDisponibles: ILiquidacionNominaEmpleadoConceptoDTO[] = [];
  estado: number = 0;

  constructor() {
    super()

    this.filtersEmpleado = [
      { propertyName: "selectionPopup", propertyValue: true },
      { propertyName: "contratoActivo", propertyValue: true },
      { propertyName: "isMultipleSelect", propertyValue: true },
      { propertyName: "emptyUserFilters", propertyValue: true },
      { propertyName: "IsMultipleSelectButtonHidde", propertyValue: true },
      { propertyName: "multipleSelectConfig", propertyValue: MultipleSelectConfigType.Hidden },
    ];
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.title = this.route.snapshot.data['title'];
    this.icon = this.route.snapshot.data['icon'];
    this.titleService.setTitle(this.title);

    this.FormRecord = this.settings.FormComponents();

    this.FormRecord.disable();

    this.After();
  }


  /**
   * Abreviacion para acceder a los controles del FormRecord
   */
  public get f(): any {
    return this.FormRecord.controls;
  }

  ngOnDestroy() {
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }

  /**
   * Guardar o actualizar el registro
   */
  async onSubmit(event) {
    event.preventDefault();
    this.submitted = true;
    if (this.FormRecord.valid) {
      if (this.FormRecord.pristine) return;
      this.isLoading = true;
      if (this.processType === this.ProcessType.edit) {
        this.UpdateRecord();
      } else {
        this.SaveRecord();
      }
    } else {
      this.isLoading = false;
    }
  }

  /**
   * Guardar
   */
  SaveRecord() {
    this.service
      .Guardar<number, ILiquidacionNominaDTO>(this.FormRecord.value)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([`${res}`], {
              queryParams: { mode: "ie" },
              relativeTo: this.route,
            });

            this.FormRecord.markAsPristine();
            this.FormRecord.markAsUntouched();
          }

        }
      });
  }

  /**
   * Actualizar
   */
  UpdateRecord() {
    this.isLoading = true;
    this.submitted = true;
    if (this.FormRecord.valid && !this.FormRecord.pristine) {
      let data = this.FormRecord.value as ILiquidacionNominaDTO;
      data.id = this.id;
      data.liquidacionNominaContratoEmpleado = this.dataGridEmpleados.map(employee => ({
        idContrato: employee.idContrato,
        descripcion: "",
        id: employee.idLiquidacion,
        idLiquidacionNomina: this.id,
      } as ILiquidacionNominaContratoEmpleadoDTO));

      this.service
        .Actualizar<boolean, ILiquidacionNominaDTO>(data)
        .subscribe({
          next: (res) => {
            this.isLoading = false;
            if (res) {
              this.router.navigate([], {
                queryParams: { mode: "u" },
                queryParamsHandling: "merge",
              });

              this.FormRecord.markAsPristine();
              this.FormRecord.markAsUntouched();

              this.LoadRecord();
            }


          }
        });
    } else {
      this.isLoading = false;
    }
  }

  /**
   * Eliminar
   */
  DeleteRecord() {
    this.isDeleting = true;
    this.isLoading = true;
    const userSearchParams = {
      id: this.id
    };
    this.service.Eliminar<boolean>(userSearchParams).subscribe({
      next: (res) => {
        if (res) {
          this.isDeleting = false;
          this.router.navigate(["../"], {
            queryParams: { mode: "d" },
            queryParamsHandling: "merge",
            relativeTo: this.route,
          });
        }

      }
    });
  }

  /**
   * Abre alerta para eliminar registro
   */
  dialogEliminar() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Eliminar liquidación";
    dialogSettings.description =
      "¿Estas seguro que desea eliminar esta liquidación?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Si, Eliminar", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
          console.log("close", result);
        } else {
          if (result.value) {
            this.DeleteRecord();
          }
        }
      });
  }


  dialogCancelarLiquidacion() {

    if (this.estado == 4) {

      this.presentToast("No se puede cancelar una liquidación en estado de cerrada", "warning");

    } else if (this.estado == 5) {

      this.presentToast("No se puede cancelar una liquidación en estado de cancelada", "warning");
    } else {

      let dialogSettings = {} as SPlusDialogSettings;
      dialogSettings.title = "Cancelar liquidación";
      dialogSettings.description =
        "¿Estas seguro que desea cancelar esta liquidación?";
      dialogSettings.actions = [
        { text: "Cancelar" },
        { text: "Si, Cancelar", themeColor: "warning", value: true },
      ];
      this.splusFormsDialogsService
        .Show(dialogSettings)
        .pipe(takeUntil(this.unsubcribe$))
        .subscribe((result) => {
          if (result instanceof SPlusDialogCloseResult) {
            console.log("close", result);
          } else {
            if (result.value) {
              this.CambiarEstado(EstadoOrden.Cancelada);
            }
          }
        });

    }
  }




  openedAgregarEmpleado() {
    this.openedDiagAgregarEmpleado = true;
    this.selectedItems = this.dataGridEmpleados.map(s => s.id);
  }


  openedLiquidarMasivoEmpleado() {
    this.openedDiagLiquidarMasivoEmpleado = true;
    this.selectedItems = this.dataGridEmpleados.map(s => s.id);

    console.log(this.dataGridEmpleados);

  }

  dataEmpleadoALiquidar: any;
  PopupLiquidarEmpleado(item) {
    this.dataEmpleadoALiquidar = item;
    this.idLiquidacionEmpleado = item?.idLiquidacion;
    this.openedDiagLiquidarEmpleado = true;

    // Estos son los conceptos configurados de la institucion
    this.CargarConceptos();



  }

  public closeLiquidarEmpleado(status: string): void {
    this.openedDiagLiquidarEmpleado = false;
    this.dataEmpleadoALiquidar = null;
    this.dataConceptosAplicados = []
    this.dataConceptosDisponibles = []
    this.dataConceptosDisponiblesOrigiales = []
    this.totalDevengado = 0;
    this.totalDeducciones = 0;
    this.total = 0;
  }
  public closeLiquidarMasivoEmpleado(status: string): void {
    this.openedDiagLiquidarMasivoEmpleado = false;
    this.dataConceptosAplicados = []
    this.dataConceptosDisponibles = []
    this.dataConceptosDisponiblesOrigiales = []
    this.totalDevengado = 0;
    this.totalDeducciones = 0;
    this.total = 0;
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }
  public data: any[] = [
    {
      text: "Owner",
      id: 1,
      employees: [
        { text: "Ana Trujillo", id: 2 },
        { text: "Antonio Moreno", id: 3 },
        { text: "Martín Sommer", id: 4 },
      ],
    },
    {
      text: "Order Administrator",
      id: 5,
      employees: [
        { text: "Christina Berglund", id: 6 },
        { text: "Sven Ottlieb", id: 7 },
      ],
    },
    {
      text: "Sales Manager",
      id: 8,
      employees: [{ text: "Roland Mendel", id: 9 }],
    },
  ];

  onGuardarData() {
    this.loadingLiquidar = true;
    let dataToSave = this.DatosLiquidacion.liquidacionNominaContratoEmpleado.find(s => s.id == this.idLiquidacionEmpleado);


    if (!dataToSave) return;
    dataToSave.totalDeduccion = this.totalDeducciones;
    dataToSave.totalDevengado = this.totalDevengado;
    dataToSave.total = this.total;
    dataToSave.liquidacionNominaEmpleadoConcepto = this.dataConceptosAplicados;
    if (!dataToSave.liquidacionNominaEmpleadoConcepto.some(s => s.idTipoConcepto == 1 || s.idTipoConcepto == 2)) {
      let dialogSettings = {} as SPlusDialogSettings;
      dialogSettings.title = "Error Guardar Conceptos";
      dialogSettings.appendTo = this.containerRef;
      dialogSettings.description =
        "No se pueden guardar los conceptos, ya que debe haber al menos uno de tipo Devengado o Deducción.";
      dialogSettings.actions = [{ text: "Cerrar" }];
      this.splusFormsDialogsService.Show(dialogSettings);
      this.loadingLiquidar = false;
      return;

    }
    this.service.ActualizarConceptos<boolean>(dataToSave).subscribe({
      //this.service.ReCalcularConceptos<ILiquidacionNominaEmpleadoConceptoDTO[]>(this.dataSelected).subscribe({
      next: (res) => {
        if (res) {
          this.loadingLiquidar = false;
          this.openedDiagLiquidarEmpleado = false;
          let itemLioquidar = this.dataGridEmpleados.find(s => s.id == this.dataEmpleadoALiquidar.id);
          if (itemLioquidar) {
            itemLioquidar.liquidado = true;
            this.CargarConceptos();
          }



        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Guardar Conceptos";
        dialogSettings.description =
          "Hubo un error al intentar guardar los conceptos, por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.splusFormsDialogsService.Show(dialogSettings);
        this.loadingLiquidar = false;
      },
    });

  }

  onValueChange() {
    this.datosValidos = false;
  }
  openedAgregarConseptos() {
    this.tabsComponent.selectTab(0);
    this.showbtnActions = false;
    this.dataConceptosDisponibles = this.dataConceptosDisponibles.filter(s => !this.dataSelectedDisponibles.includes(s));
    this.dataConceptosDisponiblesOrigiales = this.dataConceptosDisponibles;
    this.dataConceptosAplicados = [...this.dataConceptosAplicados, ...this.dataSelectedDisponibles];
    this.dataConceptosAplicados.sort((a, b) => a.idConcepto - b.idConcepto);
    this.dataSelectedDisponibles = [];
    this.dataSelectedAplicados = [];
    this.indexSelectionRowAplicados = [];
    this.indexSelectionRowDisponibles = [];
    this.ValidarTotales();
    this.tabsComponent.selectTab(1);
    this.showbtnActions = true;
  }

  selecctionChageConceptosAplicados(dataSelect: number[]) {
    let selectData = [];
    dataSelect.forEach((element) => {
      const data = this.dataConceptosAplicados.find(s => s.idConcepto == element);
      if (data)
        selectData.push(data);
    });
    this.dataSelectedAplicados = selectData;
    //console.log(this.dataSelected);
  }
  selecctionChageConceptosDisponibles(dataSelect: number[]) {
    let selectData = [];
    dataSelect.forEach((element) => {
      const data = this.dataConceptosDisponibles.find(s => s.idConcepto == element);
      if (data)
        selectData.push(data);
    });
    this.dataSelectedDisponibles = selectData;
    //console.log(this.dataSelected);
  }
  public addNewEmployee() {

  }

  public closeEmpleado(status: string): void {
    //console.log(`Dialog result: ${status}`);
    this.openedDiagAgregarEmpleado = false;
  }

  callLoadSelect() {
    if (this.searchListEmployees) {
      this.searchListEmployees.LoadSelect();
    }

    this.openedDiagAgregarEmpleado = false;
    this.fitColumns()
  }



  /**
   * formulario en modo edicción
   */
  goEdit() {
    this.routingState.goEdit();

    this.fitColumns()
  }

  /**
   * Cancelar insercion o edición
   */
  cancelEdit() {
    if (this.processType === ProcessType.create) {
      this.goback();
    } else if (this.processType === ProcessType.edit) {
      // validar si se modificó el formulario visualmente
      if (!this.FormRecord.pristine) {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cancelar edición";
        dialogSettings.isHtml = true;
        dialogSettings.description =
          "Hay cambios pendientes por guardar. <br/>¿Estás seguro que desea cancelar la edición?";
        dialogSettings.actions = [
          { text: "Seguir Editando" },
          { text: "Si, Cancelar", themeColor: "primary", value: true },
        ];
        this.splusFormsDialogsService
          .Show(dialogSettings)
          .pipe(takeUntil(this.unsubcribe$))
          .subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult) {
              console.log("close", result);
            } else {
              if (result.value) {
                this.cancelUpdate();
              }
            }
          });
      } else {
        this.goback();
      }
    }
  }

  dialogEliminarEmpleadosSelecionados() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Remover Empleados Seleccionados";
    dialogSettings.appendTo = this.containerRef;
    dialogSettings.description =
      "¿Estás seguro que desea remover estos empleados de la liquidación?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Sí, Remover", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
        } else {
          if (result.value) {
            this.dataGridEmpleados = this.dataGridEmpleados.filter(s => !this.empleadosEliminar.includes(s));
            this.dataGridEmpleadosOriginal = this.dataGridEmpleados;
            this.showDeleteButton = false;
            this.showLiquidacionMasivaButton = false;
            this.closePop = true;
            this.indexSelectionRow = [];
            this.FormRecord.markAsDirty();
            this.FormRecord.updateValueAndValidity();
            setTimeout(() => {
              this.closePop = false;
            }, 500)
          }
        }
      });
  }
  dialogEliminarConceptosAplicadosSelecionados() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Remover Conceptos Seleccionados";
    dialogSettings.appendTo = this.containerRef;
    dialogSettings.description =
      "¿Estás seguro que desea remover estos conceptos de la liquidación del empleado?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Sí, Remover", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
        } else {
          if (result.value) {
            this.dataConceptosAplicados = this.dataConceptosAplicados.filter(s => !this.dataSelectedAplicados.includes(s));
            this.dataConceptosDisponibles = [...this.dataConceptosDisponibles, ...this.dataSelectedAplicados];
            this.dataConceptosDisponiblesOrigiales = this.dataConceptosDisponibles;
            this.closePop = true;
            this.dataSelectedDisponibles = [];
            this.dataSelectedAplicados = [];
            this.indexSelectionRowAplicados = [];
            this.indexSelectionRowDisponibles = [];
            this.ValidarTotales();
            this.datosValidos = false;
            setTimeout(() => {
              this.closePop = false;
            }, 500)
          }
        }
      });
  }


  calcularTotales() {
    return this.dataGridEmpleados.reduce(
      (acumulador, empleado) => ({
        totalDeducciones: acumulador.totalDeducciones + empleado.totalDeduccion,
        totalDevengados: acumulador.totalDevengados + empleado.totalDevengado
      }),
      { totalDeducciones: 0, totalDevengados: 0 }
    );
  }

  /**
   * Cargar el registro por su id
   */
  LoadRecord() {

    this.isLoading = true;
    const userSearchParams = {
      id: this.id
    };
    this.service.Buscar<ILiquidacionNominaDTO>(userSearchParams).subscribe({
      next: (res) => {
        this.isDeleting = false;
        if (res) {

          res.fechaInicio = this.intl.parseDate(res.fechaInicio.toString(), ["G", "d"]);
          if (res.fechaFin) {
            res.fechaFin = this.intl.parseDate(res.fechaFin.toString(), ["G", "d"]);
          }

          this.estado = res.estado;

          if (this.estado == 4 || this.estado == 5) {
            this.f.nombre.disable();
            this.f.fechaInicio.disable()
            this.f.fechaFin.disable()
          } else {
            this.f.nombre.enable();
            this.f.fechaInicio.enable()
            this.f.fechaFin.enable()
          }

          this.DatosLiquidacion = res;
          if (this.DatosLiquidacion) {

            this.dataGridEmpleados = this.mapToDataGridEmpleados(this.DatosLiquidacion.liquidacionNominaContratoEmpleado);
            this.dataGridEmpleadosOriginal = this.dataGridEmpleados;
            this.fitColumns();
            this.FormRecord.patchValue(this.DatosLiquidacion);
            this.isLoading = false;

          }
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cargar liquidacion";
        dialogSettings.description =
          "Hubo un error al intentar cargar el liquidación por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.splusFormsDialogsService.Show(dialogSettings);
      },
    });
  }

  MarcarComoLiquidada() {

    this.isLoading = true;

    const userSearchParams = { id: this.id };
    this.service.Buscar<ILiquidacionNominaDTO>(userSearchParams).subscribe({
      next: (res) => {

        if (res && res.liquidacionNominaContratoEmpleado && res.liquidacionNominaContratoEmpleado.length > 0 && res.liquidacionNominaContratoEmpleado.every(registro => registro.liquidado)) {

          this.CambiarEstado(EstadoOrden.Liquidada);
          this.isLoading = false;
        }
        else {
          this.presentToast("No se encontró información completa para marcar como liquidada esta nómina", "warning");
          this.isLoading = false;
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Marcar Como Liquidada";
        dialogSettings.description =
          "Hubo un error al intentar cambiar el estado de la liquidación por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.splusFormsDialogsService.Show(dialogSettings);

        this.isLoading = false;
      },
    });
  }


  MarcarComoRevision() {
    this.CambiarEstado(EstadoOrden.Revision);
  }

  MarcarComoCerrada() {

    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Cerrar liquidación";
    dialogSettings.isHtml = true;
    dialogSettings.description =
      `Esta acción cerrará la liquidación, y no podrá realizarse ninguna modificación después.
     <br> <br>¿Está seguro de que desea proceder con el cierre?
      `;
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Si, Cerrar la liquidación", themeColor: "primary", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
          console.log("close", result);
        } else {
          if (result.value) {
            this.CambiarEstado(EstadoOrden.Cerrada);
          }
        }
      });


  }

  private CambiarEstado(estado: EstadoOrden) {
    this.isLoading = true;
    this.submitted = true;


    let data = {
      idLiquidacion: this.id,
      estado: estado
    };

    this.service
      .CambiarEstado<boolean>(data)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([], {
              queryParams: { mode: "u" },
              queryParamsHandling: "merge",
            });
            this.LoadRecord();
          }
        }
      });
  }

  mapToDataGridEmpleados(
    liquidacionNominaContratoEmpleado: ILiquidacionNominaContratoEmpleadoDTO[]
  ): any[] {
    return liquidacionNominaContratoEmpleado.map(item => ({
      id: item.empleado?.id,
      idLiquidacion: item?.id,
      nombre: item.empleado?.nombreCompleto,
      numeroDocumento: item.empleado?.numeroDocumento,
      sexo: item.empleado?.idSexo === 1 ? "FEMENINO" : "MASCULINO",
      estado: item.empleado?.estado,
      fechaNacimiento: item.empleado?.fechaNacimiento,
      tipoDocumento: item.empleado?.tipoDocumento,
      cargo: item.empleado?.cargo,
      contrato: null,
      idContrato: item.idContrato,
      liquidado: item.liquidado,
      totalDeduccion: item.totalDeduccion,
      totalDevengado: item.totalDevengado,

    }));
  }



  GetSelectionEmpleado(empleados: Empleados[] | Empleados) {
    this.FormRecord.markAsDirty();

    if (Array.isArray(empleados)) {
      this.indexSelectionRow = [];
      this.dataGridEmpleados = [...this.dataGridEmpleados, ...empleados];
      this.dataGridEmpleadosOriginal = this.dataGridEmpleados;
    }
    this.openedDiagAgregarEmpleado = false;
  }

  selecctionChage(dataSelect: number[]) {
    this.showDeleteButton = dataSelect.length > 0;
    this.showLiquidacionMasivaButton = dataSelect.length > 0;
    this.empleadosEliminar = this.dataGridEmpleados.filter(empleado =>
      dataSelect.includes(empleado.id)
    );

  }
  After() {
    this.FormRecord.enable();

    if (this.id) {
      this.LoadRecord();
    } else {
      this.SetDefaultValues();
    }
    this.searchMainControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((value) => {
          this.SearchDisponibles(value)
      });
      this.searchEmpleadosMainControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((value) => {
          this.SearchEmpleados(value)
      });
    this.isLoading = false;
  }

  SearchDisponibles(search: string) {
    if(!search){
      this.dataConceptosDisponibles =this.dataConceptosDisponiblesOrigiales;
    }
    this.dataConceptosDisponibles = this.dataConceptosDisponiblesOrigiales.filter(s => s.llaveConceptoAplicada.toLowerCase().includes(search.toLowerCase()));

  }
  SearchEmpleados(search: string) {
    if(!search){
      this.dataGridEmpleados =this.dataGridEmpleadosOriginal;
    }
    this.dataGridEmpleados = this.dataGridEmpleadosOriginal.filter(s => s.nombre?.toLowerCase().includes(search.toLowerCase()));

  }
  /**
   * Cancelar edicion
   */
  cancelUpdate() {
    this.FormRecord.patchValue(this.DatosLiquidacion);
    this.FormRecord.markAsPristine();
    this.router.navigate([], {
      queryParams: { mode: null },
      queryParamsHandling: "merge",
    });
  }

  override InternalControlProcess() {
    const params = this.util.decodeURLParams(this.router.url);
    const mode = params.mode;

    if (this.route.routeConfig && this.route.routeConfig.path === ":id") {
      if (mode === "e") {
        this.processType = ProcessType.edit;
      } else if (mode === "ie") {
        this.processType = ProcessType.edit;
        this.setMessageProcess("Confirmación", `${this.title} guardado exitosamente`, "success");

        setTimeout(() => {

          this.showMessageProcess = false;
        }, 3000);
      }
      else {
        this.processType = ProcessType.view;
      }

      if (mode === "i" || mode === "u") {
        this.setMessageProcess("Confirmación", `${this.title} guardado exitosamente`, "success");

      }

      if (mode === "df") {
        this.processType = ProcessType.view;
        this.setMessageProcess("Error", `No se puede eliminar el ${this.title} porque tiene información asociada`, "error");
      }
    } else {
      if (mode === "d") {
        this.processType = ProcessType.create;
        this.setMessageProcess("Confirmación", `El ${this.title} fue eliminado exitosamente`, "success");
      }

      if (mode === "df") {
        this.processType = ProcessType.edit;
        this.setMessageProcess("Error", `No se puede eliminar el ${this.title} porque tiene información asociada`, "error");
      }
    }
  }

  toggleSelectAll() {
    this.selectAllFlag = !this.selectAllFlag;
  }


  presentToast(message: string, type) {
    let notificationSettings = {} as SPlusNotificationSettings;
    notificationSettings.content = message;
    notificationSettings.type = type;
    notificationSettings.hideAfter = 5000;

    this.notificationService.Show(notificationSettings);
  }


  /**
   * Limpiar formulario
   */
  clearForm() {
    this.FormRecord.reset();
    this.FormRecord.updateValueAndValidity();
    // Valores por defecto
    this.SetDefaultValues();
    this.submitted = false;
  }
  /**
   * Setea valores por defecto
   */
  SetDefaultValues() {
  }


  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.gridEmpleadosRef.autoFitColumns();
      });
  }



  onRecalcularData() {
    this.loadingLiquidar = true;
    this.datosValidos = false;
    this.service.ReCalcularConceptos<ILiquidacionNominaEmpleadoConceptoDTO[]>(this.dataConceptosAplicados).subscribe({
      //this.service.ReCalcularConceptos<ILiquidacionNominaEmpleadoConceptoDTO[]>(this.dataSelected).subscribe({
      next: (res) => {
        if (res) {
          this.dataConceptosAplicados = res;
          this.ValidarTotales();
          this.datosValidos = true;

        }
        this.loadingLiquidar = false;
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cargar Recalcular Conceptos";
        dialogSettings.description =
          "Hubo un error al intentar recalcular los conceptos configurados por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.splusFormsDialogsService.Show(dialogSettings);
        this.loadingLiquidar = false;
      },
    });
  }
  ValidarTotales() {
    this.totalDevengado = 0;
    this.totalDeducciones = 0;
    this.total = 0;
    this.dataConceptosAplicados.forEach(s => {
      if (s.tipoConcepto == 'Devengados') {
        this.totalDevengado += s.valorPersonalizado;
      }
      if (s.tipoConcepto == 'Deducciones') {
        this.totalDeducciones += s.valorPersonalizado;
      }

    });
    this.total = this.totalDevengado - this.totalDeducciones;
  }
  dataConceptosDisponibles: ILiquidacionNominaEmpleadoConceptoDTO[] = [];
  dataConceptosDisponiblesOrigiales: ILiquidacionNominaEmpleadoConceptoDTO[] = [];
  dataConceptosAplicados: ILiquidacionNominaEmpleadoConceptoDTO[] = [];

  onTabChange(tab: any) {
    this.showbtnActions = tab?.index === 1;
  }
  CargarConceptos() {

    try {
      if (!this.dataEmpleadoALiquidar) return;
      this.loadingLiquidar = true;
      const userSearchParams = {
        idEmpleado: this.dataEmpleadoALiquidar.id.toString(),
        idLiquidacion: this.idLiquidacionEmpleado.toString()

      };
      this.service.ConceptosConfigurados<ILiquidacionNominaEmpleadoConceptoDTO[]>(userSearchParams).subscribe({
        next: (res) => {
          if (res) {
            this.dataConceptosDisponibles = res.filter(x => !x.id);
            this.dataConceptosDisponiblesOrigiales = this.dataConceptosDisponibles;
            this.dataConceptosAplicados = res.filter(x => x.id);
            if (this.dataConceptosAplicados.length > 0) {
              this.tabsComponent.selectTab(1);
              this.showbtnActions = true;
            }
            else if (this.dataConceptosDisponibles.length > 0) {
              this.tabsComponent.selectTab(0);
            }
            this.dataSelectedDisponibles = this.dataConceptosDisponibles
              .filter(item => item.preseleccionado);
            this.indexSelectionRowAplicados = this.dataSelectedDisponibles
              .map(item => item.idConcepto);
            this.ValidarTotales();
            if(this.dataConceptosAplicados && this.dataConceptosAplicados.length > 0){
              this.onRecalcularData();
            }
          }
        },
        error: (err) => {
          if (err instanceof HttpErrorResponse && err.status == 401) return;
          console.warn("Error critico de lado del cliente", err);
          let dialogSettings = {} as SPlusDialogSettings;
          dialogSettings.title = "Cargar Conceptos Configurados";
          dialogSettings.description =
            "Hubo un error al intentar cargar los conceptos configurados por favor intentelo más tarde";
          dialogSettings.actions = [{ text: "Cerrar" }];
          this.splusFormsDialogsService.Show(dialogSettings);

        },
      });
    } catch (error) {
      console.error(error);
    } finally {

      this.loadingLiquidar = false;
    }


  }


}


export enum EstadoOrden {
  Borrador = 1,
  Liquidada = 2,
  Revision = 3,
  Cerrada = 4,
  Cancelada = 5
}
