import { UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { FormSearch } from "src/app/models/base/FormSearch";
import { Component, Inject, inject } from "@angular/core";
import {
  TegettFormControl,
  TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import {
  DateFilter,
  MultipleFilter,
  MultipleFilterData,
} from "@src/app/models/Tegett/SimpleFilterData";
import { TiposDocumentos } from "@src/app/models/SaludPlus/TiposDocumentos.model";
import Validation from "@src/app/components/Core/directives/generals/validatorError";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { lastValueFrom } from "rxjs";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { EndPointMethods, FiltersData } from "@src/app/models/base/filterModel";
import { LiquidacionService } from "../services/liquidacion.service";
import { LiquidacionSettings } from "./liquidacion.settings";

@Component({
    selector: "app-liquidacionSearch",
    template: "",
    standalone: false
})
export class LiquidacionSearch implements BaseSearchSetting {

  tiposDocumentos: MultipleFilter;
  selectionPopup = false;

  private liquidacionServices = inject(LiquidacionService);
  private router = inject(Router)
  private route = inject(ActivatedRoute)
  private settings = inject(LiquidacionSettings)

  constructor() { }

  /**
  * Funcion para armar la busqueda
  */
  loadFormSearch(): FormSearch {
    const liquidacionSearch = new FormSearch();

    liquidacionSearch.formComponents = this.settings.FormComponents()
    liquidacionSearch.configSearch = {
      title: "Liquidacion",
      titleIcon: "fa-duotone fa-solid fa-books",
      apiSearch: ControllerApiList.Nomina.Liquidacion,
      apiSearchResult: "Listado",
      apiSearchVersion: 3,
      filtersRecently: this.LoadSimpleFilter(),
      buttonNewRecordText: "Nuevo Liquidacion",
      MethodRequest: EndPointMethods.GET,
      buttonNewRecordClick: () => this.newRecord(),

      settings: [
        { objectKey: "id", sortV3: "desc", visible: false },
      ],
      fields: [
        {
          renderLabel: "ID",
          objectKey: "id",
          autoSize: true,
        },
        {
          renderLabel: "Liquidacion",
          objectKey: "nombre",
          pipe: "ColumnCustom", icon: "inherit",
          click: (row) => this.goView(row),
          autoSize: true,
          selectionPopup: this.selectionPopup,
        },
        {
          objectKey: "fechaInicio",
          //pipe: "date",
          renderLabel: "Fecha inicio del período",
        },
        {
          objectKey: "fechaFin",
          //pipe: "date",
          renderLabel: "Fecha fin del período",
        },
        {
          objectKey: "descripcion",
          renderLabel: "Descripción",
        },
      ],
      filters: [
        { objectKey: "nombre", type: SearchTypeFilter.Text },



      ],
    };
    return liquidacionSearch;
  }


  async Load(custom: FiltersData[] = []) {

    return true;

  }


  ConfigDate() {
    let filter = {} as DateFilter;
    filter.label = "Fecha Nacimiento";
    filter.name = "fechaNacimiento";
    filter.type = SearchTypeFilter.DateFilter;

    return filter;
  }


  newRecord() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  goEdit(id: number) {
    this.router.navigate(["../", id], {
      queryParams: { mode: "e" },
      queryParamsHandling: "merge",
      relativeTo: this.route,
    });
  }



  /**
   * ir al registro
   * @param row linea proveniente de la busqueda
   */
  goView(row: any) {
    if (row.id) {
      this.router.navigate(["../", row.id], { relativeTo: this.route });
    }
  }



  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1"
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2"
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3"
      }
    ];
    return filterdataRecently
  }
}
